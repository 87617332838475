import {ErrorHandler, LOCALE_ID, NgModule, Provider, Type} from '@angular/core';
import {BrowserModule, HammerModule} from '@angular/platform-browser';

import Dutch from '@angular/common/locales/nl';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ComponentsModule} from './components/components.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {AuthenticationService} from './services/authentication.service';
import {JWT_OPTIONS, JwtModule} from '@auth0/angular-jwt';
import {environment} from '../environments/environment';
import {ProjectService} from './services/project.service';
import {ProjectServiceMock} from './services/mock/project.service.mock';
import {ProjectJobServiceImpl} from './services/project-job.service';
import {DateHttpInterceptorService} from './interceptors/date-http-interceptor.service';
import {ProjectJobServiceMock} from './services/mock/project-job.service.mock';
import {ProjectJobAnswerServiceImpl} from './services/project-job-answer.service';
import {ProjectJobAnswerServiceMock} from './services/mock/project-job-answer.service.mock';
import {AccordionController} from './controllers/accordion.controller';
import {DatePipe, registerLocaleData} from '@angular/common';
import {ProjectFormServiceImpl} from './services/project-form.service';
import {ProjectJobAnswerMetaServiceImpl} from './services/project-job-answer-meta.service';
import {ProjectJobAnswerMetaServiceMock} from './services/mock/project-job-answer-meta.service.mock';

import {ProjectObjectServiceImpl} from './services/project-object.service';
import {ProjectFormServiceMock} from './services/mock/project-form.service.mock';
import {ProjectObjectServiceMock} from './services/mock/project-object.service.mock';
import {TranslateModule} from '@ngx-translate/core';
import {TRANSLATION_EN, TRANSLATION_NL} from './translations';
import {LanguageService} from './services/language.service';
import {JwtConfig} from '@auth0/angular-jwt/lib/angular-jwt.module';
import {ErrorHandlerService} from './services/app-insights/error-handler.service';
import {ObstructionCodeServiceImpl} from './services/obstruction-code.service';
import {ObstructionCodeServiceMock} from './services/mock/obstruction-code.service.mock';
import {SortablejsModule} from './library/ngx-sortablejs/sortablejs.module';
import {AuthenticationProviderServiceImpl} from './services/authentication-provider-service-impl.service';
import {AuthenticationProviderServiceMock} from './services/mock/authentication-provider.service.mock';
import {NgSelectModule} from '@ng-select/ng-select';
import {PaulaDatabaseServiceImpl} from './services/paula-database.service';
import {PaulaDatabaseServiceMock} from './services/mock/paula-database.service.mock';
import {BaseUrlInterceptor} from './interceptors/base-url.interceptor';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ProjectJobAttachmentServiceImpl} from './services/project-job-attachment.service';
import {ProjectJobAttachmentServiceMock} from './services/mock/project-job-attachment.service.mock';

registerLocaleData(Dutch);

export function jwtOptionsFactory(authenticationService: AuthenticationService): JwtConfig {
    const allowedDomains: (string | RegExp)[] = [/^.*\.check.secuur.io$/, 'localhost:8080'];
    if (environment.apiHost) {
        // Add custom apiHost from environment to allowedDomains
        const apiUrl = new URL(environment.apiHost);
        const protocol = apiUrl.protocol;
        const nonStandardPort = ('https:' === protocol && apiUrl.port !== '443') || ('http:' === protocol && apiUrl.port !== '80');
        allowedDomains.push(`${apiUrl.hostname}${nonStandardPort && apiUrl.port ? ':' + apiUrl.port : ''}`);
    }

    return {
        tokenGetter: () => {
            return authenticationService.getJwtToken();
        },
        allowedDomains: allowedDomains,
        disallowedRoutes: [/^\/oauth2.*$/g]
    };
}

export function serviceWithMock(provide: string, service: Type<unknown>, mock: Type<unknown>, extra: object = {}): Provider {
    return {
        useClass: environment.useMock ? mock : service,
        provide: provide,
        ...extra
    };
}

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserAnimationsModule,
        SortablejsModule.forRoot({ animation: 150 }),
        BrowserModule,
        HammerModule,
        TranslateModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 4000,
        }),
        JwtModule.forRoot({
            config: {
                throwNoTokenError: true,
                skipWhenExpired: false
            },
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [AuthenticationService],
            },
        }),
        NgSelectModule,
        AppRoutingModule,
        ComponentsModule], providers: [
        { provide: LOCALE_ID, useValue: 'nl' },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        serviceWithMock('ProjectService', ProjectService, ProjectServiceMock),
        serviceWithMock('ProjectJobService', ProjectJobServiceImpl, ProjectJobServiceMock),
        serviceWithMock('ProjectJobAnswerService', ProjectJobAnswerServiceImpl, ProjectJobAnswerServiceMock),
        serviceWithMock('PaulaDatabaseService', PaulaDatabaseServiceImpl, PaulaDatabaseServiceMock),
        serviceWithMock('ProjectFormService', ProjectFormServiceImpl, ProjectFormServiceMock),
        serviceWithMock('ProjectObjectService', ProjectObjectServiceImpl, ProjectObjectServiceMock),
        serviceWithMock('ProjectJobAnswerMetaService', ProjectJobAnswerMetaServiceImpl, ProjectJobAnswerMetaServiceMock),
        serviceWithMock('ObstructionCodeService', ObstructionCodeServiceImpl, ObstructionCodeServiceMock),
        serviceWithMock('AuthenticationProviderService', AuthenticationProviderServiceImpl, AuthenticationProviderServiceMock),
        serviceWithMock('ProjectJobAttachmentService', ProjectJobAttachmentServiceImpl, ProjectJobAttachmentServiceMock),
        LanguageService,
        AccordionController,
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BaseUrlInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DateHttpInterceptorService,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
    constructor(
        private languageService: LanguageService,
    ) {
        languageService.init({
            nl: TRANSLATION_NL,
            en: TRANSLATION_EN,
        });
    }
}
