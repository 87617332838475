import {ProjectJobService} from '../project-job.service';
import {AnyProjectJobForm, ProjectJobStatus} from '../../models/project-job-form';
import {PageResponse} from '../../models/page-response';
import {Observable, of} from 'rxjs';
import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {map} from 'rxjs/operators';
import {ProjectJobFormExtra, ProjectJobLocationAnswer} from '../../models/project-job-form-extra';
import { HttpResponse } from '@angular/common/http';
import {ProjectJobAnswer} from '../../models/project-job-answer';
import {Injectable} from '@angular/core';

export const mockProjectJobs: AnyProjectJobForm[] = [
    {
        id: 1,
        type: 'jobForm',
        code: 'job-1',
        title: 'Stationstraat lantaarnpalen opdracht',
        objectOmschrijvingKort: 'Korte omschrijving met meer info',
        objectOmschrijvingLang: 'lange omschrijving met meer info',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'j.hobert@vwtelecom.com',
        status: 'AvailableForInspection',
        askLocation: false,
        showLocationOnMap: false,
        askExecutorToFillFormAgain: false,
        originalProjectForm: 1,
        extraFields: {} as ProjectJobFormExtra,
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 2,
                position: 1000,
                title: 'Hoofdstuk 1',
                questions: [
                    {
                        id: 3,
                        type: 'text',
                        title: 'Vraag 1.1',
                        descriptionType: 'text',
                        description: 'Vul maar gewoon wat in a.u.b.',
                        imageDescription: null,
                        helpText: 'Dit is een stukje help text, omdat de vraag nog niet duidelijk genoeg is.',
                        required: true,
                        position: 1001,
                        reference: 'question-1001',
                        preFillEnabled: false,
                        preFillValue: '',
                        multiline: false,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    }
                ],
                questionDependency: []
            },
            {
                id: 4,
                position: 2000,
                title: 'Hoofdstuk 2',
                questions: [
                    {
                        id: 5,
                        type: 'text',
                        title: 'Vraag 2.1',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 2001,
                        reference: 'question-2001',
                        preFillEnabled: false,
                        preFillValue: '',
                        multiline: false,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    },
                    {
                        id: 4,
                        type: 'text',
                        title: 'Vraag 2.2',
                        descriptionType: 'text',
                        description: 'Is pipo lekker aan het vissen?',
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 2002,
                        reference: 'question-2002',
                        preFillEnabled: true,
                        preFillValue: 'Antwoord op vraag 2.2',
                        multiline: false,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    },
                    {
                        id: 6,
                        type: 'text',
                        title: 'Vraag 2.3',
                        descriptionType: 'text',
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 2003,
                        reference: 'question-2003',
                        preFillEnabled: false,
                        preFillValue: 'Antwoord op vraag 2.3',
                        multiline: false,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    }
                ],
                questionDependency: []
            }
        ],
        answers: [
            {
                id: 2,
                node: null,
                position: 2001,
                value: 'Test antwoord 2',
                updatedAt: new Date(),
                updatedBy: 'Testsmurf',
                job: 1,
                revision: 0,
                remarkImage: null,
                remarkText: null,
            }
        ],
        answerRevision: 0,
        submits: [],
        project: 1,
        copyAnswers: false,
    },
    {
        id: 2,
        type: 'jobForm',
        code: 'job-2',
        title: 'Kanaal almelo opdracht',
        objectOmschrijvingKort: '',
        objectOmschrijvingLang: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'j.hobert@vwtelecom.com',
        status: 'AvailableForInspection',
        askLocation: false,
        showLocationOnMap: false,
        askExecutorToFillFormAgain: false,
        originalProjectForm: 1,
        extraFields: {} as ProjectJobFormExtra,
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 7,
                position: 1000,
                title: 'Hoofdstuk 1',
                questions: [
                    {
                        id: 8,
                        type: 'text',
                        title: 'Vraag 1.1',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1001,
                        reference: 'question-1001',
                        preFillEnabled: false,
                        preFillValue: '',
                        multiline: false,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    }
                ],
                questionDependency: []
            }
        ],
        answers: [],
        answerRevision: 0,
        submits: [],
        project: 1,
        copyAnswers: false,
    },
    {
        id: 3,
        type: 'jobForm',
        code: 'job-3',
        title: 'Meerkeuze vragen test',
        objectOmschrijvingKort: '',
        objectOmschrijvingLang: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'r.vlaskamp@vwtelecom.com',
        status: 'AvailableForInspection',
        askLocation: false,
        showLocationOnMap: false,
        askExecutorToFillFormAgain: false,
        originalProjectForm: 1,
        extraFields: {} as ProjectJobFormExtra,
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 1,
                position: 1000,
                title: 'Hoofdstuk 1',
                questions: [
                    {
                        id: 1,
                        type: 'choice',
                        title: 'Keuzevraag single',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1001,
                        reference: 'question-1001',
                        preFillEnabled: false,
                        preFillValue: '',
                        multiple: false,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        choices: [
                            {
                                id: 'choice-1',
                                position: 1,
                                title: 'Ja',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: 'choice-2',
                                position: 2,
                                title: 'Nee',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            }
                        ],
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    },
                    {
                        id: 2,
                        type: 'choice',
                        title: 'Keuzevraag multiple',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1002,
                        reference: 'question-1002',
                        preFillEnabled: false,
                        preFillValue: '',
                        multiple: true,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        choices: [
                            {
                                id: 'choice-1',
                                position: 1,
                                title: 'Optie 1',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: 'choice-2',
                                position: 2,
                                title: 'Optie 2',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: 'choice-3',
                                position: 3,
                                title: 'Optie 3',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            }
                        ],
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    },
                    {
                        id: 9,
                        type: 'choice',
                        title: 'Keuzevraag single al ingevuld',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1003,
                        reference: 'question-1003',
                        preFillEnabled: false,
                        preFillValue: '',
                        multiple: false,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        choices: [
                            {
                                id: 'choice-1',
                                position: 1,
                                title: 'Optie 1',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: 'choice-2',
                                position: 2,
                                title: 'Optie 2',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: 'choice-3',
                                position: 3,
                                title: 'Optie 3',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            }
                        ],
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    },
                    {
                        id: 10,
                        type: 'choice',
                        title: 'Keuzevraag multiple vooraf ingevuld',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1004,
                        reference: 'question-1004',
                        preFillEnabled: true,
                        preFillValue: 'choice-1,choice-3',
                        multiple: true,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        choices: [
                            {
                                id: 'choice-1',
                                position: 1,
                                title: 'Optie 1',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: 'choice-2',
                                position: 2,
                                title: 'Optie 2',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: 'choice-3',
                                position: 3,
                                title: 'Optie 3',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            }
                        ],
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    }
                ],
                questionDependency: []
            }
        ],
        answers: [
            {
                id: 1,
                node: null,
                position: 1003,
                value: 'choice-1',
                updatedAt: new Date(),
                updatedBy: 'Testsmurf',
                job: 3,
                revision: 0,
                remarkImage: null,
                remarkText: null,
            }
        ],
        answerRevision: 0,
        submits: [],
        project: 1,
        copyAnswers: false,
    },
    {
        id: 4,
        type: 'jobForm',
        code: 'job-4',
        title: 'Spoorbrug wierden',
        objectOmschrijvingKort: '',
        objectOmschrijvingLang: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'w.slaghekke@vwtelecom.com',
        status: 'InProgress',
        askLocation: false,
        showLocationOnMap: false,
        askExecutorToFillFormAgain: false,
        originalProjectForm: 1,
        extraFields: {} as ProjectJobFormExtra,
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 10,
                position: 1000,
                title: 'Hoofdstuk 1',
                questions: [
                    {
                        id: 11,
                        type: 'text',
                        title: 'Vraag 1.1',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1001,
                        reference: 'question-1001',
                        preFillEnabled: false,
                        preFillValue: '',
                        multiline: false,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    }
                ],
                questionDependency: []
            }
        ],
        answers: [],
        answerRevision: 0,
        submits: [],
        project: 1,
        copyAnswers: false,
    },
    {
        id: 5,
        type: 'jobForm',
        code: 'job-5',
        title: 'Sluis in Ijmuiden',
        objectOmschrijvingKort: '',
        objectOmschrijvingLang: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 't.reinders@vwtelecom.com',
        status: 'Rejected',
        askLocation: false,
        showLocationOnMap: false,
        askExecutorToFillFormAgain: false,
        originalProjectForm: 1,
        extraFields: {} as ProjectJobFormExtra,
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 13,
                position: 1000,
                title: 'Hoofdstuk 1',
                questions: [
                    {
                        id: 14,
                        type: 'text',
                        title: 'Vraag 1.1',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1001,
                        reference: 'question-1001',
                        preFillEnabled: false,
                        preFillValue: '',
                        multiline: false,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    }
                ],
                questionDependency: []
            }
        ],
        answers: [
            {
                id: 1,
                node: null,
                position: 1001,
                value: 'Test antwoord 1',
                updatedAt: new Date(),
                updatedBy: 'Testsmurf',
                job: 2,
                revision: 0,
                remarkImage: null,
                remarkText: null,
            }
        ],
        answerRevision: 0,
        submits: [],
        project: 1,
        copyAnswers: false,
    },
    {
        id: 15,
        type: 'jobForm',
        code: 'job-15',
        title: 'Brug in Almelo',
        objectOmschrijvingKort: '',
        objectOmschrijvingLang: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'j.hobert@vwtelecom.com',
        status: 'AvailableForInspection',
        askLocation: false,
        showLocationOnMap: false,
        askExecutorToFillFormAgain: false,
        originalProjectForm: 1,
        extraFields: {} as ProjectJobFormExtra,
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 16,
                position: 1000,
                title: 'Hoofdstuk 1',
                questions: [
                    {
                        id: 17,
                        type: 'number',
                        title: 'Number vraag 1.1',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1001,
                        reference: 'question-1001',
                        preFillEnabled: false,
                        preFillValue: '',
                        totalDecimal: 3,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    }
                ],
                questionDependency: []
            },
            {
                id: 19,
                position: 2000,
                title: 'Hoofdstuk 2',
                questions: [
                    {
                        id: 20,
                        type: 'number',
                        title: 'Number vraag 2.1',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 2001,
                        reference: 'question-2001',
                        preFillEnabled: false,
                        preFillValue: '',
                        toleranceEnabled: false,
                        toleranceValue: '',
                        totalDecimal: 0,
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    },
                    {
                        id: 21,
                        type: 'choice',
                        title: 'Keuzevraag multiple met tolerantie',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 2002,
                        reference: 'question-2002',
                        preFillEnabled: false,
                        preFillValue: '',
                        multiple: true,
                        toleranceEnabled: true,
                        toleranceValue: 'choice-1,choice-3',
                        choices: [
                            {
                                id: 'choice-1',
                                position: 1,
                                title: 'Optie 1',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: 'choice-2',
                                position: 2,
                                title: 'Optie 2',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: 'choice-3',
                                position: 3,
                                title: 'Optie 3',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            }
                        ],
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    },
                    {
                        id: 22,
                        type: 'number',
                        title: 'Number vraag 2.3',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 2003,
                        reference: 'question-2003',
                        preFillEnabled: false,
                        preFillValue: '',
                        totalDecimal: 3,
                        toleranceEnabled: true,
                        toleranceValue: '5^10',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    }
                ],
                questionDependency: []
            },
        ],
        answers: [],
        answerRevision: 0,
        submits: [],
        project: 1,
        copyAnswers: false,
    },
    {
        id: 16,
        type: 'jobForm',
        code: 'job-16',
        title: 'Datum vragen test',
        objectOmschrijvingKort: '',
        objectOmschrijvingLang: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'r.vlaskamp@vwtelecom.com',
        status: 'AvailableForInspection',
        askLocation: false,
        showLocationOnMap: false,
        askExecutorToFillFormAgain: false,
        originalProjectForm: 1,
        extraFields: {} as ProjectJobFormExtra,
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 1,
                position: 1000,
                title: 'Hoofdstuk 1',
                questions: [
                    {
                        id: 1,
                        type: 'date',
                        title: 'Deze datum is al ingevuld',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1001,
                        reference: 'question-1001',
                        preFillEnabled: false,
                        preFillValue: '',
                        time: true,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    },
                    {
                        id: 1,
                        type: 'date',
                        title: 'Alleen een datum invullen',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1002,
                        reference: 'question-1002',
                        preFillEnabled: false,
                        preFillValue: '',
                        time: false,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    },
                    {
                        id: 1,
                        type: 'number',
                        title: 'Deze nummer vraag mag niet ingevuld zijn met een datum antwoord',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1003,
                        reference: 'question-1003',
                        preFillEnabled: false,
                        preFillValue: '',
                        totalDecimal: 0,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    },
                ],
                questionDependency: []
            }
        ],
        answers: [
            {
                id: 1,
                job: 16,
                node: null,
                position: 1001,
                value: '2019-01-01T00:00:00.000Z',
                updatedAt: new Date(),
                updatedBy: 'Testsmurf',
                revision: 0,
                remarkImage: null,
                remarkText: null,
            }
        ],
        answerRevision: 0,
        submits: [],
        project: 1,
        copyAnswers: false,
    },
    {
        id: 30,
        type: 'jobForm',
        code: 'job-30',
        title: 'Afbeelding vragen set',
        objectOmschrijvingKort: '',
        objectOmschrijvingLang: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'r.vlaskamp@vwtelecom.com',
        status: 'AvailableForInspection',
        askLocation: false,
        showLocationOnMap: false,
        askExecutorToFillFormAgain: false,
        originalProjectForm: 1,
        extraFields: {} as ProjectJobFormExtra,
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 31,
                position: 1000,
                title: 'Hoofdstuk 1',
                questions: [
                    {
                        id: 32,
                        type: 'photo',
                        title: 'Maak een foto van willekeurige objecten',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1001,
                        reference: 'question-1001',
                        preFillEnabled: false,
                        preFillValue: '',
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    }, {
                        id: 33,
                        type: 'photo',
                        title: 'Beschikbare foto\'s van willekeurige objecten',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1002,
                        reference: 'question-1002',
                        preFillEnabled: false,
                        preFillValue: '',
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    },
                    {
                        id: 34,
                        type: 'signature',
                        title: 'Mag ik uw handtekening?',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1003,
                        reference: 'question-1003',
                        preFillEnabled: false,
                        preFillValue: '',
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                        showSummaryEnabled: true,
                    }
                ],
                questionDependency: []
            }
        ],
        answers: [],
        answerRevision: 0,
        submits: [],
        project: 2,
        copyAnswers: false,
    },
    {
        id: 40,
        type: 'jobForm',
        code: 'job-40',
        title: 'Tolerantie en afkeuring flow test',
        objectOmschrijvingKort: '',
        objectOmschrijvingLang: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'r.vlaskamp@vwtelecom.com',
        status: 'AvailableForInspection',
        askLocation: false,
        showLocationOnMap: false,
        askExecutorToFillFormAgain: false,
        originalProjectForm: 1,
        extraFields: {} as ProjectJobFormExtra,
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 41,
                position: 1000,
                title: 'Hoofdstuk 1',
                questions: [
                    {
                        id: 42,
                        type: 'text',
                        title: 'Vraag 1.1',
                        descriptionType: 'text',
                        description: 'Vul maar gewoon wat in a.u.b.',
                        imageDescription: null,
                        helpText: 'Dit is een stukje help text, omdat de vraag nog niet duidelijk genoeg is.',
                        required: true,
                        position: 1001,
                        reference: 'question-1001',
                        preFillEnabled: false,
                        preFillValue: '',
                        multiline: false,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    },
                    {
                        id: 43,
                        type: 'choice',
                        title: 'Keuzevraag multiple met tolerantie',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1002,
                        reference: 'question-1002',
                        preFillEnabled: false,
                        preFillValue: '',
                        multiple: true,
                        toleranceEnabled: true,
                        toleranceValue: 'choice-1,choice-3',
                        choices: [
                            {
                                id: 'choice-1',
                                position: 1,
                                title: 'Optie 1',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: 'choice-2',
                                position: 2,
                                title: 'Optie 2',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: 'choice-3',
                                position: 3,
                                title: 'Optie 3',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            }
                        ],
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    },
                    {
                        id: 44,
                        type: 'number',
                        title: 'Number vraag 2.3',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1003,
                        reference: 'question-1003',
                        preFillEnabled: false,
                        preFillValue: '',
                        totalDecimal: 3,
                        toleranceEnabled: true,
                        toleranceValue: '5^10',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    }
                ],
                questionDependency: []
            }
        ],
        answers: [
            {
                id: 45,
                job: 40,
                node: null,
                position: 1001,
                value: 'test antwoord',
                updatedAt: new Date(),
                updatedBy: 'Testsmurf',
                revision: 0,
                remarkImage: null,
                remarkText: null,
            },
            {
                id: 46,
                job: 40,
                node: null,
                position: 1002,
                value: 'choice-2',
                updatedAt: new Date(),
                updatedBy: 'Testsmurf',
                revision: 0,
                remarkImage: null,
                remarkText: null,
            },
            {
                id: 47,
                job: 40,
                node: null,
                position: 1003,
                value: '4.333',
                updatedAt: new Date(),
                updatedBy: 'Testsmurf',
                revision: 0,
                remarkImage: null,
                remarkText: null,
            },
        ],
        answerRevision: 0,
        submits: [],
        project: 1,
        copyAnswers: false,
    },
    {
        id: 50,
        type: 'jobForm',
        code: 'job-50',
        title: 'Afhankelijkheid flow test',
        objectOmschrijvingKort: '',
        objectOmschrijvingLang: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'w.slaghekke@vwtelecom.com',
        status: 'AvailableForInspection',
        askLocation: false,
        showLocationOnMap: false,
        askExecutorToFillFormAgain: false,
        originalProjectForm: 1,
        extraFields: {} as ProjectJobFormExtra,
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 51,
                position: 1000,
                title: 'Hoofdstuk 1',
                questions: [
                    {
                        id: 52,
                        type: 'choice',
                        title: 'Vraag 1.1 wil je vraag 1.2 zien?',
                        descriptionType: 'text',
                        description: 'Vul maar gewoon wat in a.u.b.',
                        imageDescription: null,
                        helpText: 'Dit is een stukje help text, omdat de vraag nog niet duidelijk genoeg is.',
                        required: true,
                        position: 1001,
                        reference: 'question-1001',
                        preFillEnabled: false,
                        preFillValue: '',
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                        multiple: false,
                        choices: [
                            {
                                id: '8976f2fc-d284-42fb-bbc0-59c6d66451a5',
                                position: 1,
                                title: 'Ja',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: '59bcf697-5241-40be-ba20-0f4601817493',
                                position: 2,
                                title: 'Nee',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            }
                        ]
                    },
                    {
                        id: 53,
                        type: 'choice',
                        title: 'Keuzevraag multiple met tolerantie',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1002,
                        reference: 'question-1002',
                        preFillEnabled: false,
                        preFillValue: '',
                        multiple: true,
                        toleranceEnabled: true,
                        toleranceValue: 'choice-1,choice-3',
                        choices: [
                            {
                                id: 'choice-1',
                                position: 1,
                                title: 'Optie 1',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: 'choice-2',
                                position: 2,
                                title: 'Optie 2',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: 'choice-3',
                                position: 3,
                                title: 'Optie 3',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            }
                        ],
                        questionDependency: [
                            {
                                id: '8976f2fc-d284-42fb-bbc0-59c6d66451a5',
                                position: 1,
                                title: 'Ja',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            }
                        ],
                        addToSummaryEnabled: true,
                    },
                    {
                        id: 54,
                        type: 'choice',
                        title: 'Welke vragen wil je nog meer invullen?',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: true,
                        position: 1003,
                        reference: 'question-1003',
                        preFillEnabled: false,
                        preFillValue: '',
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                        multiple: true,
                        choices: [
                            {
                                id: 'b8b133ce-d3cf-4dad-a548-436a4c4cf4e3',
                                position: 1,
                                title: 'Vraag A',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: '5522f3dc-9728-434a-bd64-a2b6bee4cfef',
                                position: 2,
                                title: 'Vraag B',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            },
                            {
                                id: 'ff445af6-de3c-4cda-ad9a-bdca7ce59e7e',
                                position: 3,
                                title: 'Geen van beide',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            }
                        ]
                    },
                    {
                        id: 55,
                        type: 'number',
                        title: 'Number vraag A',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: true,
                        position: 1004,
                        reference: 'question-1004',
                        preFillEnabled: false,
                        preFillValue: '',
                        totalDecimal: 3,
                        toleranceEnabled: true,
                        toleranceValue: '5^10',
                        questionDependency: [
                            {
                                id: 'b8b133ce-d3cf-4dad-a548-436a4c4cf4e3',
                                position: 1,
                                title: 'Vraag A',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            }
                        ],
                        addToSummaryEnabled: true,
                    },
                    {
                        id: 56,
                        type: 'number',
                        title: 'Number vraag B',
                        descriptionType: null,
                        description: null,
                        imageDescription: null,
                        helpText: null,
                        required: false,
                        position: 1005,
                        reference: 'question-1005',
                        preFillEnabled: false,
                        preFillValue: '',
                        totalDecimal: 3,
                        toleranceEnabled: true,
                        toleranceValue: '5^10',
                        questionDependency: [
                            {
                                id: '5522f3dc-9728-434a-bd64-a2b6bee4cfef',
                                position: 2,
                                title: 'Vraag B',
                                imageRemark: false,
                                textRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            }
                        ],
                        addToSummaryEnabled: true,
                    }
                ],
                questionDependency: []
            }
        ],
        answers: [],
        answerRevision: 0,
        submits: [],
        project: 2,
        copyAnswers: false,
    },
    {
        id: 60,
        type: 'jobForm',
        code: 'job-60',
        title: 'Objectvraag test',
        objectOmschrijvingKort: '',
        objectOmschrijvingLang: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'r.vlaskamp@vwtelecom.com',
        status: 'AvailableForInspection',
        askLocation: false,
        showLocationOnMap: false,
        askExecutorToFillFormAgain: false,
        originalProjectForm: 1,
        extraFields: {} as ProjectJobFormExtra,
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 51,
                position: 1000,
                title: 'Hoofdstuk 1',
                questions: [
                    {
                        id: 80,
                        type: 'object',
                        title: 'Een objectvraag',
                        description: null,
                        descriptionType: 'text',
                        helpText: null,
                        imageDescription: null,
                        position: 1001,
                        reference: 'question-1001',
                        preFillEnabled: false,
                        preFillValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                        required: false,
                        toleranceEnabled: false,
                        toleranceValue: '',
                    }
                ],
                questionDependency: []
            }
        ],
        answers: [],
        answerRevision: 0,
        submits: [],
        project: 2,
        copyAnswers: false,
    },
    {
        id: 70,
        type: 'jobForm',
        code: 'job-70',
        title: 'Locatievraag test',
        objectOmschrijvingKort: '',
        objectOmschrijvingLang: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'r.vlaskamp@vwtelecom.com',
        status: 'AvailableForInspection',
        askLocation: true,
        showLocationOnMap: true,
        askExecutorToFillFormAgain: false,
        originalProjectForm: 1,
        extraFields: {} as ProjectJobFormExtra,
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 31,
                position: 1000,
                title: 'Hoofdstuk 1',
                questions: [
                    {
                        id: 9,
                        type: 'text',
                        title: 'Vraag 1.1',
                        descriptionType: 'text',
                        description: 'Vul maar gewoon wat in a.u.b.',
                        imageDescription: null,
                        helpText: 'Dit is een stukje help text, omdat de vraag nog niet duidelijk genoeg is.',
                        required: true,
                        position: 1001,
                        reference: 'question-1001',
                        preFillEnabled: false,
                        preFillValue: '',
                        multiline: false,
                        toleranceEnabled: false,
                        toleranceValue: '',
                        questionDependency: [],
                        addToSummaryEnabled: true,
                    }
                ],
                questionDependency: []
            }
        ],
        answers: [],
        answerRevision: 0,
        submits: [],
        project: 2,
        copyAnswers: false,
    },
    {
        id: 80,
        type: 'jobForm',
        code: 'job-80',
        title: 'Tabelvraag tolerantie test',
        objectOmschrijvingKort: '',
        objectOmschrijvingLang: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'r.vlaskamp@vwtelecom.com',
        status: 'AvailableForInspection',
        askLocation: false,
        showLocationOnMap: false,
        askExecutorToFillFormAgain: false,
        originalProjectForm: 1,
        extraFields: {} as ProjectJobFormExtra,
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 31,
                position: 1000,
                title: 'Hoofdstuk 1',
                questions: [
                    {
                        id: 9,
                        type: 'table',
                        title: 'Vraag 1.1',
                        descriptionType: 'text',
                        description: 'Vul maar gewoon wat in a.u.b.',
                        imageDescription: null,
                        helpText: 'Dit is een stukje help text, omdat de vraag nog niet duidelijk genoeg is.',
                        required: true,
                        position: 1001,
                        reference: 'question-1001',
                        preFillEnabled: false,
                        preFillValue: '',
                        toleranceEnabled: true,
                        questionDependency: [],
                        addToSummaryEnabled: true,
                        questionCount: 4,
                        questionLabel: 'Dwarsliggers',
                        averageTolerance: '10^20',
                        toleranceValue: '5^25'
                    }
                ],
                questionDependency: []
            }
        ],
        answers: [],
        answerRevision: 0,
        submits: [],
        project: 2,
        copyAnswers: false,
    },
    {
        id: 89,
        type: 'jobForm',
        title: 'test, test',
        code: '00002',
        status: 'AvailableForInspection',
        createdBy: 'j.hobert@vwtelecom.com',
        createdAt: new Date(),
        updatedAt: new Date(),
        answers: [],
        answerRevision: 0,
        askLocation: false,
        showLocationOnMap: true,
        askExecutorToFillFormAgain: false,
        originalProjectForm: 1,
        extraFields: {} as ProjectJobFormExtra,
        objectOmschrijvingLang: 'test',
        objectOmschrijvingKort: 'test',
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 124,
                title: 'H1',
                position: 1000,
                questions: [
                    {
                        type: 'text',
                        id: 125,
                        title: 'Verplichte vraag 1',
                        descriptionType: 'text',
                        description: null,
                        imageDescription: null,
                        position: 1001,
                        reference: 'question-1001',
                        addToSummaryEnabled: false,
                        helpText: null,
                        preFillEnabled: false,
                        preFillValue: '',
                        toleranceEnabled: false,
                        toleranceValue: '',
                        required: true,
                        questionDependency: [],
                        multiline: false
                    },
                    {
                        type: 'text',
                        id: 126,
                        title: 'Verplichte vraag 2',
                        descriptionType: 'text',
                        description: null,
                        imageDescription: null,
                        position: 1002,
                        reference: 'question-1002',
                        addToSummaryEnabled: false,
                        helpText: null,
                        preFillEnabled: false,
                        preFillValue: '',
                        toleranceEnabled: false,
                        toleranceValue: '',
                        required: true,
                        questionDependency: [],
                        multiline: false
                    }
                ],
                questionDependency: []
            }
        ],
        submits: [
            {
                id: 127,
                submittedBy: 'j.hobert@vwtelecom.com',
                oldStatus: 'Concept',
                newStatus: 'AvailableForInspection',
                submittedAt: new Date(),
                comment: 'undefined'
            }
        ],
        project: 2,
        copyAnswers: false,
    },
    {
        id: 90,
        type: 'jobForm',
        code: 'job-90',
        title: 'Tabular vraag',
        objectOmschrijvingKort: '',
        objectOmschrijvingLang: '',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: 'r.vlaskamp@vwtelecom.com',
        status: 'AvailableForInspection',
        askLocation: false,
        showLocationOnMap: false,
        askExecutorToFillFormAgain: false,
        originalProjectForm: 1,
        extraFields: {} as ProjectJobFormExtra,
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 31,
                position: 1000,
                title: 'Hoofdstuk 1',
                questions: [
                    {
                        id: 99,
                        type: 'tabular',
                        title: 'Vraag 1.1',
                        descriptionType: 'text',
                        description: 'Vul maar gewoon wat in a.u.b.',
                        imageDescription: null,
                        helpText: 'Dit is een stukje help text, omdat de vraag nog niet duidelijk genoeg is.',
                        required: true,
                        position: 1001,
                        reference: 'question-1001',
                        preFillEnabled: false,
                        preFillValue: '',
                        toleranceEnabled: true,
                        questionDependency: [],
                        addToSummaryEnabled: true,
                        columns: [
                            {
                                type: 'text',
                                key: 'testTextColumn',
                                label: 'Test tekst column',
                                config: {multiline: false}
                            },
                            {
                                type: 'number',
                                key: 'testNumberColumn',
                                label: 'Test nummer column',
                                config: {totalDecimal: 0, showAverage: false, showSum: false}
                            }
                        ],
                        toleranceValue: ''
                    },
                    {
                        id: 100,
                        type: 'tabular',
                        title: 'Vraag 1.2',
                        descriptionType: 'text',
                        description: 'Vul maar gewoon wat in a.u.b.',
                        imageDescription: null,
                        helpText: 'Dit is een stukje help text, omdat de vraag nog niet duidelijk genoeg is.',
                        required: true,
                        position: 1002,
                        reference: 'question-1002',
                        preFillEnabled: false,
                        preFillValue: '',
                        toleranceEnabled: true,
                        questionDependency: [],
                        addToSummaryEnabled: true,
                        columns: [
                            {
                                type: 'text',
                                key: 'testTextColumn',
                                label: 'Test tekst column',
                                config: {multiline: true}
                            },
                            {
                                type: 'number',
                                key: 'testNumberColumn',
                                label: 'Test nummer column',
                                config: {totalDecimal: 0, showAverage: false, showSum: false}
                            }
                        ],
                        toleranceValue: ''
                    }
                ],
                questionDependency: []
            }
        ],
        answers: [],
        answerRevision: 0,
        submits: [],
        project: 2,
        copyAnswers: false,
    },
    {
        id: 100,
        type: 'jobForm',
        askExecutorToFillFormAgain: false,
        extraFields: {} as ProjectJobFormExtra,
        submits: [],
        copyAnswers: false,
        title: 'Databank keuze vraag',
        code: 'job-100',
        status: 'AvailableForInspection',
        project: 1,
        originalProjectForm: 369,
        createdBy: 'c.j.a.dezeeuw@vwtelecom.com',
        createdAt: new Date(),
        updatedAt: new Date(),
        answers: [],
        answerRevision: 0,
        askLocation: false,
        showLocationOnMap: true,
        objectOmschrijvingLang: '001 kort',
        objectOmschrijvingKort: '001 kort',
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        chapters: [
            {
                id: 2094,
                title: 'Hoofdstuk 1',
                position: 1000,
                questions: [
                    {
                        type: 'choice',
                        id: 2095,
                        title: 'Keuze 1',
                        descriptionType: 'text',
                        description: null,
                        imageDescription: null,
                        position: 1001,
                        reference: 'question-1001',
                        addToSummaryEnabled: false,
                        helpText: null,
                        preFillEnabled: false,
                        preFillValue: '',
                        toleranceEnabled: false,
                        toleranceValue: '',
                        required: false,
                        questionDependency: [],
                        choices: [
                            {
                                id: '9aebb757-efe7-44ce-91aa-0b0d5bb947e3',
                                title: 'Anders',
                                position: 1,
                                textRemark: false,
                                imageRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false,
                            },
                            {
                                id: '1e9addb3-9367-4f7c-ba2d-c1bc53b6e60c',
                                title: 'Groente',
                                position: 2,
                                textRemark: false,
                                imageRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false,
                            },
                            {
                                id: 'f5afe431-a652-48cd-ba0e-e8999e29bdcb',
                                title: 'Fruit',
                                position: 3,
                                textRemark: false,
                                imageRemark: false,
                                textRemarkRequired: false,
                                imageRemarkRequired: false
                            }
                        ],
                        multiple: false
                    },
                    {
                        type: 'database',
                        id: 2096,
                        title: 'Kies soort eten',
                        descriptionType: 'text',
                        description: null,
                        imageDescription: null,
                        position: 1002,
                        reference: 'question-1002',
                        addToSummaryEnabled: false,
                        helpText: null,
                        preFillEnabled: false,
                        preFillValue: '',
                        toleranceEnabled: false,
                        toleranceValue: '',
                        required: false,
                        questionDependency: [],
                        config: {
                            database: 75,
                            databaseAttribute: null,
                            referencedAttribute: null,
                            referencedQuestion: null,
                            userCanIgnoreFilter: null
                        }
                    },
                    {
                        type: 'database',
                        id: 2097,
                        title: 'Kies welk eten',
                        descriptionType: 'text',
                        description: null,
                        imageDescription: null,
                        position: 1003,
                        reference: 'question-1003',
                        addToSummaryEnabled: false,
                        helpText: null,
                        preFillEnabled: false,
                        preFillValue: '',
                        toleranceEnabled: false,
                        toleranceValue: '',
                        required: false,
                        questionDependency: [],
                        config: {
                            database: 76,
                            databaseAttribute: 'Soort',
                            referencedAttribute: null,
                            referencedQuestion: null,
                            userCanIgnoreFilter: true
                        }
                    }
                ],
                questionDependency: []
            }]
    }, {
        id: 101,
        type: 'layeredJobForm',
        askExecutorToFillFormAgain: false,
        extraFields: {} as ProjectJobFormExtra,
        submits: [],
        copyAnswers: false,
        title: 'Gelaagd formulier',
        code: 'job-101',
        status: 'AvailableForInspection',
        project: 1,
        originalProjectForm: 370,
        createdBy: 'w.slaghekke@recognize.nl',
        createdAt: new Date(),
        updatedAt: new Date(),
        answers: [],
        answerRevision: 0,
        askLocation: false,
        showLocationOnMap: true,
        objectOmschrijvingLang: '001 kort',
        objectOmschrijvingKort: '001 kort',
        objectLocationType: 'Linear',
        obstructionAllowed: true,
        nodes: [],
        layers: [
            {
                id: 1,
                type: 'subtypeLayer',
                name: 'Subtypelaag',
                order: 1,
                subtypes: [
                    {
                        id: 3,
                        name: 'Subtype 1',
                        questions: [],
                    }
                ]
            },
            {
                id: 2,
                type: 'databaseLayer',
                name: 'Databaselaag',
                order: 2,
                databaseId: 76,
                config: {
                    databaseAttribute: null,
                    referencedAttribute: null,
                    referencedQuestion: null,
                    userCanIgnoreFilter: null
                },
                questions: []
            },
            {
                id: 3,
                type: 'databaseLayer',
                name: 'Databaselaag 2',
                order: 3,
                databaseId: 76,
                config: {
                    databaseAttribute: null,
                    referencedAttribute: null,
                    referencedQuestion: null,
                    userCanIgnoreFilter: null
                },
                questions: []
            },
            {
                id: 4,
                type: 'subtypeLayer',
                name: 'Gebrek',
                order: 4,
                subtypes: [
                    {
                        id: 4,
                        name: 'Subtype 1',
                        questions: [
                            {
                                id: 34,
                                type: 'text',
                                title: 'Vraag 1.1',
                                descriptionType: 'text',
                                description: 'Vul maar gewoon wat in a.u.b.',
                                imageDescription: null,
                                helpText: 'Dit is een stukje help text, omdat de vraag nog niet duidelijk genoeg is.',
                                required: true,
                                position: 1,
                                reference: 'question-1',
                                preFillEnabled: false,
                                preFillValue: '',
                                multiline: false,
                                toleranceEnabled: false,
                                toleranceValue: '',
                                questionDependency: [],
                                addToSummaryEnabled: true,
                            }
                        ],
                    }
                ]
            },
        ]
    }
];

@Injectable()
export class ProjectJobServiceMock extends AbstractCrudServiceMock<AnyProjectJobForm> implements ProjectJobService {
    constructor() {
        super(mockProjectJobs);
    }

    list(projectId: number, page: number, status?: string, search?: string[], sort?: string[]): Observable<PageResponse<AnyProjectJobForm>> {
        return super.getAll().pipe(
            map((pageResponse: PageResponse<AnyProjectJobForm>) => {
                pageResponse.content = pageResponse.content
                    .filter(projectJob => {
                        return projectJob.status === status
                            && projectJob.project === projectId;
                    })
                    .filter(projectJob => {
                        if (!search || search.length === 0) {
                            return true;
                        } else {
                            return search.every(searchItem => {
                                return projectJob.title.includes(searchItem) || projectJob.code.includes(searchItem)
                            })
                        }
                    });
                pageResponse.numberOfElements = pageResponse.content.length;
                pageResponse.totalElements = pageResponse.content.length;

                return pageResponse;
            })
        );
    }

    detail(id: number): Observable<AnyProjectJobForm> {
        return super.getImpl(id);
    }

    create() {
        return of(mockProjectJobs[0]);
    }

    transition(projectId: number, jobId: number, status: string): Observable<AnyProjectJobForm> {
        const jobForm = this.data.find(item => +item.id === jobId);
        if (jobForm === undefined) {
            throw new Error('Job not found');
        }

        return this.put({
            id: jobId,
            status: status,
            answerRevision: jobForm.answerRevision + 1,
        });
    }

    rejectWithObstruction(
        projectId: number,
        jobId: number
    ): Observable<AnyProjectJobForm> {
        const jobForm = this.data.find(item => +item.id === jobId);
        if (jobForm === undefined) {
            throw new Error('Job not found');
        }

        return this.put({
            id: jobId,
            status: ProjectJobStatus.Rejected,
            answerRevision: jobForm.answerRevision + 1,
        });
    }

    nonExistingIds(): Observable<number[]> {
        return of([]);
    }

    saveLocationQuestionAnswer(): Observable<ProjectJobLocationAnswer> {
        return of({} as ProjectJobLocationAnswer);
    }

    generateJobCode(): Observable<string> {
        return of(Math.round(Math.random() * 10000).toString().padStart(5, '0'));
    }

    downloadJobExport(): Observable<HttpResponse<Blob>> {
        throw new Error('Not implemented');
    }

    openJobExport(): Promise<void> {
        throw new Error('Not implemented');
    }

    upsertAnswer(jobId: number, answer: ProjectJobAnswer) {
        const job = this.data.find(item => item.id === jobId);
        if (job === undefined) {
            throw new Error('Job not found');
        }

        const existingAnswerIndex = job.answers.findIndex(existingItem => {
            return existingItem.job === answer.job
                && existingItem.node?.id === answer.node?.id
                && existingItem.position === answer.position
                && existingItem.revision === answer.revision;
        });

        if (existingAnswerIndex !== -1) {
            job.answers[existingAnswerIndex] = answer;
        } else {
            job.answers.push(answer);
        }
    }
}
