import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, switchMap} from 'rxjs';
import {AuthenticationProviderConfig} from '../models/authentication-provider-config';
import {retry, take} from 'rxjs/operators';
import {WorkspaceConfigService} from './workspace-config.service';

export interface AuthenticationProviderService {
    initialize(): Promise<void>;

    readonly authenticationProvider$: Observable<AuthenticationProviderConfig[] | null>;
}

@Injectable({providedIn: 'root'})
export class AuthenticationProviderServiceImpl implements AuthenticationProviderService {
    constructor(private workspaceConfigService: WorkspaceConfigService) {
    }

    private authenticationProvidersSubject = new BehaviorSubject<AuthenticationProviderConfig[] | null>(null);
    readonly authenticationProvider$ = this.authenticationProvidersSubject.asObservable();

    async initialize() {
        this.listAuthenticationProviders().pipe(
            retry(3),
            take(1))
            .subscribe(value => {
                this.authenticationProvidersSubject.next(value);
            });
    }

    private listAuthenticationProviders(): Observable<AuthenticationProviderConfig[]> {
        // Used fetch api to ensure no interceptors are interfering
        return this.workspaceConfigService.workspaceConfig$.pipe(
            switchMap((workspaceConfig) => {
                if (workspaceConfig === null) {
                    return of<AuthenticationProviderConfig[]>([]);
                }

                return fetch(`${workspaceConfig.apiHost}/oauth2/authentication-provider-configs`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json'
                    }
                }).then<AuthenticationProviderConfig[]>(it => it.json());
            })
        );
    }
}
