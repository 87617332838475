import {Injectable} from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {firstValueFrom} from 'rxjs';
import {db} from '../db/db';
import {PaulaDatabase, PaulaDatabaseWithLastModified} from '../models/paula-database';
import {AnyProjectJobForm} from '../models/project-job-form';
import {WorkspaceConfigService} from './workspace-config.service';
import {getFormDatabaseIds} from '../utils/get-form-database-ids';
import {AuthenticationService} from './authentication.service';

export interface PaulaDatabaseService {
    get(id: number): Promise<PaulaDatabase>;

    downloadFormDatabases(forms: AnyProjectJobForm[]): Promise<void>;
}

@Injectable()
export class PaulaDatabaseServiceImpl implements PaulaDatabaseService {
    constructor(
        private http: HttpClient,
        private workspaceConfigService: WorkspaceConfigService,
        authenticationService: AuthenticationService,
    ) {
        authenticationService.logout$.subscribe(() => {
            db.paulaDatabases.clear();
        });
    }

    /** called by the loop in the sync-service and by the form filler (wip)
     * @param id {number} The id of the database to get
     */
    async get(id: number): Promise<PaulaDatabase> {
        const cachedDatabase = await db.paulaDatabases.get(id);
        // Now we have a perhaps stale version. We try to update from the server
        // If that fails we will use the above found instance instead.

        return await this.syncDatabase(id, cachedDatabase);
    }

    /*
    Inbouwen in de form-sync.service.ts
    Testen door het mocken van de http client
     */
    private async syncDatabase(id: number, cachedDatabase: PaulaDatabaseWithLastModified | undefined): Promise<PaulaDatabaseWithLastModified> {
        try {
            const paulaDatabaseResponse = await firstValueFrom(this.http.get<PaulaDatabase>(
                `/app-api/v1/databases/${id}`,
                {
                    headers: cachedDatabase?.lastModified != undefined ? {'If-Modified-Since': `${cachedDatabase?.lastModified}`} : undefined,
                    observe: 'response'
                }
            ));

            const lastModifiedHeader = paulaDatabaseResponse.headers.get('Last-Modified')
            if (lastModifiedHeader == null) {
                throw new Error('Last-Modified header is missing')
            }
            if (paulaDatabaseResponse.body == null) {
                throw new Error('Body is missing')
            }
            const paulaDatabaseWithLastModified: PaulaDatabaseWithLastModified = {
                ...paulaDatabaseResponse.body, lastModified: lastModifiedHeader
            };
            await db.paulaDatabases.put(paulaDatabaseWithLastModified);

            return paulaDatabaseWithLastModified
        } catch (error: unknown) {
            if (!(error instanceof HttpErrorResponse) || error.status !== 304) {
                console.error(`Error fetching DB  with id ${id}`, error);
            }
            if (cachedDatabase === undefined) {
                throw new Error("Network or Server error and cached database is unavailable")
            }

            return cachedDatabase;
        }
    }

    async downloadFormDatabases(forms: AnyProjectJobForm[]): Promise<void> {
        const ids = getFormDatabaseIds(forms);
        const promises = [...ids].map(id => this.get(id))
        await Promise.allSettled(promises)
    }
}
