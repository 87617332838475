import {Observable} from 'rxjs';
import {ProjectJobAnswer} from '../models/project-job-answer';
import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Identifiable} from '../models/identifiable';

export interface ProjectJobAnswerService {
    postProjectJobAnswer(projectId: number, request: ProjectJobAnswer): Observable<ProjectJobAnswer & Identifiable>;
}

@Injectable()
export class ProjectJobAnswerServiceImpl implements ProjectJobAnswerService {
    constructor(private httpClient: HttpClient) {
    }

    postProjectJobAnswer(projectId: number, projectJobAnswer: ProjectJobAnswer): Observable<ProjectJobAnswer & Identifiable> {
        return this.httpClient.post<ProjectJobAnswer & Identifiable>(
            `/app-api/v1/projects/${projectId}/jobs/${projectJobAnswer.job}/answers`,
            projectJobAnswer
        );
    }
}
