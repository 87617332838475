import {AbstractCrudServiceMock} from './abstract-crud.service.mock';
import {ProjectJobAnswerService} from '../project-job-answer.service';
import {ProjectJobAnswer} from '../../models/project-job-answer';
import {Observable, of, throwError} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {Identifiable} from '../../models/identifiable';
import {Inject, Injectable} from '@angular/core';
import {ProjectJobServiceMock} from './project-job.service.mock';

export const mockProjectJobAnswers: Array<ProjectJobAnswer & Identifiable> = [
    {
        id: 1,
        node: null,
        position: 1001,
        value: 'Test antwoord 1',
        updatedAt: new Date(),
        updatedBy: 'Testsmurf',
        job: 2,
        revision: 0,
        remarkImage: null,
        remarkText: null,
    },
    {
        id: 2,
        node: null,
        position: 2001,
        value: 'Test antwoord 2',
        updatedAt: new Date(),
        updatedBy: 'Testsmurf',
        job: 1,
        revision: 0,
        remarkImage: null,
        remarkText: null,
    },
];

@Injectable()
export class ProjectJobAnswerServiceMock extends AbstractCrudServiceMock<ProjectJobAnswer & Identifiable> implements ProjectJobAnswerService {

    constructor(
        @Inject('ProjectJobService') private jobServiceMock: ProjectJobServiceMock,
    ) {
        super(mockProjectJobAnswers);
    }

    postProjectJobAnswer(projectId: number, request: ProjectJobAnswer): Observable<ProjectJobAnswer & Identifiable> {
        for (const answer of mockProjectJobAnswers) {
            if (request.job === answer.job && request.node?.id === answer.node?.id && request.position === answer.position && request.revision === answer.revision) {
                throw throwError(() => (new HttpErrorResponse({status: 409})));
            }
        }

        const existingAnswerIndex = this.data.findIndex(existingItem => {
            return existingItem.job === request.job
                && existingItem.node?.id === request.node?.id
                && existingItem.position === request.position
                && existingItem.revision === request.revision;
        });

        const existingAnswer = this.data[existingAnswerIndex];
        const answer: ProjectJobAnswer & Identifiable = existingAnswerIndex !== -1 ? {
            ...existingAnswer,
            ...request,
            id: existingAnswer.id
        } : {
            ...request,
            id: this.nextId(),
        };

        this.jobServiceMock.upsertAnswer(answer.job, answer);

        return of(answer);
    }
}
