import {Observable} from 'rxjs';
import {PageResponse} from '../models/page-response';
import { HttpClient, HttpParams } from '@angular/common/http';
import {PaulaObstructionCode} from '../models/paula-obstruction-code';
import {Injectable} from '@angular/core';

export interface ObstructionCodeService {
    list(page?: number): Observable<PageResponse<PaulaObstructionCode>>;
}

@Injectable()
export class ObstructionCodeServiceImpl implements ObstructionCodeService {
    constructor(private http: HttpClient
    ) {
    }

    list(project: number, page = 0, search = ''): Observable<PageResponse<PaulaObstructionCode>> {
        const params = new HttpParams().set('page', `${page}`).set('search', search);

        return this.http.get<PageResponse<PaulaObstructionCode>>(`/app-api/v1/obstruction-code`, {params});
    }
}
