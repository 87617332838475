import {Observable} from 'rxjs';
import {PageResponse} from '../models/page-response';
import {CreatePaulaObjectDTO, PaulaObject} from '../models/paula-object';
import { HttpClient, HttpParams } from '@angular/common/http';
import {ObjectQRSearchResponse} from '../models/object-qr-search-response';
import {HttpParamsObject} from '../models/http-params-object';
import {Injectable} from '@angular/core';

export interface ProjectObjectService {
    list(project: number, page?: number, search?: string): Observable<PageResponse<PaulaObject>>;
    get(project: number, id: number): Observable<PaulaObject>;
    search(project: number, page: number, search: string, queryParams?: HttpParamsObject): Observable<PageResponse<PaulaObject>>;
    qr(project: number, search: string, queryParams?: object): Observable<ObjectQRSearchResponse>;
    create(project: number, dto: CreatePaulaObjectDTO): Observable<PaulaObject>;
}

@Injectable()
export class ProjectObjectServiceImpl implements ProjectObjectService {
    constructor(private http: HttpClient) {
    }

    list(project: number, page = 0, search = ''): Observable<PageResponse<PaulaObject>> {
        const params = new HttpParams().set('page', `${page}`).set('search', search);

        return this.http.get<PageResponse<PaulaObject>>(`/app-api/v1/projects/${project}/objects`, {params});
    }

    get(project: number, id: number): Observable<PaulaObject> {
        return this.http.get<PaulaObject>(`/app-api/v1/projects/${project}/objects/${id}`);
    }

    search(project: number, page = 0, search: string, queryParams?: HttpParamsObject): Observable<PageResponse<PaulaObject>> {
        let params = new HttpParams().set('page', '' + page).set('q', search);

        for (const key in queryParams) {
            if (key in queryParams) {
                params = params.set(key, queryParams[key]);
            }
        }

        return this.http.get<PageResponse<PaulaObject>>(`/app-api/v1/projects/${project}/objects/search`, {params});
    }

    qr(project: number, search: string, queryParams?: HttpParamsObject): Observable<ObjectQRSearchResponse> {
        let params = new HttpParams().set('q', search);

        for (const key in queryParams) {
            if (key in queryParams) {
                params = params.set(key, queryParams[key]);
            }
        }

        return this.http.get<ObjectQRSearchResponse>(`/app-api/v1/projects/${project}/objects/qr`, {params});
    }

    create(project: number, dto: CreatePaulaObjectDTO): Observable<PaulaObject> {
        return this.http.post<PaulaObject>(`/app-api/v1/projects/${project}/objects`, dto)
    }
}
