import {ProjectJobAnswer} from '../../models/project-job-answer';
import {Observable, of, Subject, throwError} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {ProjectJobAnswerMeta} from '../../models/project-job-answer-meta';
import {ProjectJobAnswerMetaApiObject, ProjectJobAnswerMetaService} from '../project-job-answer-meta.service';
import {ImageToolConfiguration} from '../../models/image-tool-configuration';
import {MetadataAnswerProperties} from '../../models/metadata-answer-properties';
import SKETCH_SET_MOCK from './project-job-answer-meta-sketch-set.json';
import {AnnotateToolConfiguration} from '../../models/annotate-tool-configuration';

export const mockProjectJobAnswerMeta: Array<ProjectJobAnswerMeta> = [
    {
        answer_id: 'asdfasdf-asdfasdf-asdfasdf',
        job: 2,
        history: [],
        photo_id: 'asdfasdf-asdfasdf-asdfasdf',
        comment: '',
        file_created_at: '2020-01-01 10:20:30',
        position: 1000,
    },
];

export class ProjectJobAnswerMetaServiceMock implements ProjectJobAnswerMetaService {
    metadataUpdated$: Subject<string> = new Subject();

    postProjectJobAnswerMeta(request: ProjectJobAnswer,
                             meta: ProjectJobAnswerMeta[]): Observable<ProjectJobAnswerMetaApiObject> {
        for (const answer of mockProjectJobAnswerMeta) {
            if (request.job === answer.job) {
                throw throwError(() => (new HttpErrorResponse({status: 409})));
            }
        }

        return of({items: meta.map((metadata) => {
                return {
                    answer_id: metadata.answer_id,
                    history: JSON.stringify(metadata.history),
                    photo_id: metadata.photo_id,
                    file_created_at: metadata.file_created_at,
                    comment: metadata.comment,
                    position: metadata.position,
                };
            })});
    }

    getProjectJobAnswerMeta(request: ProjectJobAnswer): Observable<ProjectJobAnswerMetaApiObject> {
        for (const answer of mockProjectJobAnswerMeta) {
            if (request.job === answer.job) {
                throw throwError(() => (new HttpErrorResponse({status: 409})));
            }
        }

        return of({items: mockProjectJobAnswerMeta.map((metadata) => {
                return {
                    answer_id: metadata.answer_id,
                    history: JSON.stringify(metadata.history),
                    photo_id: metadata.photo_id,
                    file_created_at: metadata.file_created_at,
                    comment: metadata.comment,
                    position: metadata.position,
                };
            })});
    }

    findLocalProjectJobAnswerMeta(): Promise<ProjectJobAnswerMeta[]> {
        return Promise.resolve(mockProjectJobAnswerMeta);
    }

    findProjectJobAnswerMeta(): Promise<ProjectJobAnswerMeta[]> {
        return Promise.resolve(mockProjectJobAnswerMeta);
    }

    async updateLocalProjectJobAnswerMeta(): Promise<void> {
    }

    async clearLocalMetadata(): Promise<void> {
        // NOOP
    }

    async removeLocalMetadataFromCurrentQuestion(): Promise<void> {
    }

    isDanglingImage(): Promise<boolean> {
        return Promise.resolve(false);
    }

    async replaceCurrentQuestionAnswerMetaId(

    ): Promise<void> {
    }

    async findImageTools(): Promise<ImageToolConfiguration> {
        return Promise.resolve(SKETCH_SET_MOCK as ImageToolConfiguration);
    }

    async findAnnotateTools(): Promise<AnnotateToolConfiguration> {
        return Promise.resolve(SKETCH_SET_MOCK as AnnotateToolConfiguration);
    }

    answerHasCommentOnCurrentQuestion(): Promise<boolean> {
        return Promise.resolve(false);
    }

    getCompleteProjectJobAnswerMeta(): Promise<ProjectJobAnswerMeta[]> {
        return Promise.resolve([]);
    }

    async addExifForUuid(): Promise<void> {
        // NOOP
    }

    getCurrentAnswerForFilename(): Promise<MetadataAnswerProperties | null> {
        throw new Error('Method not implemented.');
    }
}
